import React from "react";
import { PageProps, graphql } from "gatsby";
import { ContentstackNews } from "../data/types/ContentstackPages";
import { ContentstackArticle } from "../data/types/Contentstack";
import { FeaturedNews } from "../components/block/news/FeaturedNews/FeaturedNews";
import { ArticleList } from "../components/block/ArticleList/ArticleList";
import { YoutubeSection } from "../components/block/YoutubeSection/YoutubeSection";
import { YoutubeVideo } from "../data/types/Youtube";
import newsData from "../json/news.json";

interface NewsProps {
  allContentstackNews: { nodes: Array<ContentstackNews> };
  allContentstackArticles: {
    articles: Array<ContentstackArticle>;
  };
  // youtubeChannel: null;
}

const News: React.FC<PageProps<NewsProps>> = ({
  // data: {
  //   allContentstackNews: {
  //     nodes: [
  //       {
  //         preferences,
  //         featuredNews: { featuredArticles, headlines },
  //         loadMoreCtaText,
  //         promoAd,
  //         youtubeSection: {
  //           youtubeLogo,
  //           channelId,
  //           ctaText: youtubeSectionCtaText,
  //           isEnabled: isYoutubeSectionEnabled,
  //         },
  //       },
  //     ],
  //   },
  //   allContentstackArticles: { articles },
  //   // youtubeChannel,
  // },
}) => {
  const {
    allContentstackNews: {
      nodes: [
        {
          preferences,
          featuredNews: { featuredArticles, headlines },
          loadMoreCtaText,
          promoAd,
          youtubeSection: {
            youtubeLogo,
            channelId,
            ctaText: youtubeSectionCtaText,
            isEnabled: isYoutubeSectionEnabled,
          },
        },
      ],
    },
    allContentstackArticles: { articles },
    // youtubeChannel,
  } = newsData.result.data
  return (
    <>
      <FeaturedNews headlines={headlines} featuredArticles={featuredArticles} />
      <ArticleList
        articles={articles}
        preferencesData={preferences}
        promoAdData={promoAd}
        loadMoreCtaText={loadMoreCtaText}
        isYoutubeSectionEnabled={false}
      />
    </>
  )
};

export const query = graphql`
  query NewsQuery($language: String!) {
    allContentstackNews(filter: { publish_details: { locale: { eq: $language } } }) {
      nodes {
        title
        ...FeaturedNews
        ...ArticleList
        ...PromoAd
        ...YoutubeSection
      }
    }
    allContentstackArticles(
      sort: { fields: date, order: DESC }
      filter: { publish_details: { locale: { eq: $language } }, hide_from_newsfeeds: { eq: false } }
    ) {
      articles: nodes {
        ...ArticleData
      }
    }
    pageMetadata: pageMetaData {
      title
      description
      image_url
    }
  }
`;

export default News;
