import { gsap, Power1 } from "gsap";

export const animateArticleList = (articlesWrapper: HTMLDivElement | null) => {
  if (!articlesWrapper) return;

  const tl = gsap.timeline({ paused: true });

  tl.from(articlesWrapper.children, {
    y: 30,
    duration: 1,
    autoAlpha: 0,
    stagger: 0.1,
    ease: Power1.easeOut,
  });

  return tl;
};
