import { graphql } from "gatsby";
import React, { useMemo, useRef } from "react";
import { ColorTheme } from "../../../../data/enum/ColorTheme";
import { ContentstackArticle } from "../../../../data/types/Contentstack";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { useScrollObserver } from "../../../../hooks/useScrollObserver";
import { NewsSlider } from "../../../content/slider/NewsSlider/NewsSlider";
import DetailLine from "../../../general/borders/DetailLine/DetailLine";
import SectionTitle from "../../../general/SectionTitle/SectionTitle";
import Volt from "../../../general/Volt/Volt";
import { animateFeaturedNews } from "./animations";
import styles from "./FeaturedNews.module.scss";
export interface FeaturedNewsProps {
  headlines: Array<string>;
  featuredArticles: Array<ContentstackArticle>;
}

export const FeaturedNews: React.FC<FeaturedNewsProps> = ({ headlines, featuredArticles }) => {
  const isMobile = useIsMobile();

  const sectionRef = useRef<HTMLDivElement | null>(null);

  const observerRef = useScrollObserver<HTMLElement | null>(animateFeaturedNews, sectionRef);

  const formattedHeadlines = useMemo<Array<string>>(() => (isMobile ? [headlines.join(" ")] : headlines), [
    headlines,
    isMobile,
  ]);

  return (
    <section ref={observerRef}>
      <div className={styles.featuredNews} ref={sectionRef}>
        <div className={`container ${styles.titleContainer}`}>
          <div className="content-wrapper light">
            <DetailLine className={styles.copyWrapper} variation="halfLeft" theme={ColorTheme.LIGHT}>
              <SectionTitle className={styles.title} titleParts={formattedHeadlines} theme={ColorTheme.LIGHT} />
            </DetailLine>
          </div>
        </div>
        {featuredArticles &&
          <NewsSlider slides={featuredArticles} isFeaturedNewsCardSlider={true} />
        }
        <Volt className={styles.bottomVolt} name={"VoltBottom03"} position="bottom" />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FeaturedNews on Contentstack_news {
    featuredNews: featured_news {
      headlines: title
      featuredArticles: articles_select {
        ...ArticleData
      }
    }
  }
`;
