import React, { Fragment, useRef } from "react";
import Dropdown, { DropdownOption } from "../../general/Dropdown/Dropdown";
import { useCallback, useState, useMemo } from "react";
import { ContentstackArticle, ContentstackPromoSection } from "../../../data/types/Contentstack";
import { ContentstackFilterPreference } from "../../../data/types/Contentstack";
import { graphql } from "gatsby";
import { ArticleCard } from "../../content/cards/ArticleCard/ArticleCard";
import { PromoAd } from "../PromoAd/PromoAd";
import { animateArticleList } from "./animations";
import PrimaryButton from "../../general/button/PrimaryButton/PrimaryButton";
import styles from "./ArtcileList.module.scss";
import Loader from "../../general/Loader/Loader";
import { useScrollObserver } from "../../../hooks/useScrollObserver";
import { useIsMobile } from "../../../hooks/useIsMobile";

export interface ArticleListProps {
  articles: Array<ContentstackArticle>;
  preferencesData: ContentstackFilterPreference;
  promoAdData: ContentstackPromoSection;
  loadMoreCtaText: string;
  isYoutubeSectionEnabled: boolean;
}

export const ArticleList: React.FC<ArticleListProps> = ({
  articles,
  loadMoreCtaText,
  preferencesData: { placeholderText, defaultPreferenceText, options: filterOptions },
  promoAdData: {
    isEnabled: isPromoEnabled,
    selectedPromos: [promo],
  },
  isYoutubeSectionEnabled,
}) => {
  const displayPromoSlideIndexPosition = 5;
  const cardsToDisplayAtOnceNumber = 9;
  const isMobile = useIsMobile();
  const [currentCardIndex, setCurrentCardIndex] = useState<number>(cardsToDisplayAtOnceNumber);
  const [filteredArticles, setFilteredArticles] = useState<Array<ContentstackArticle>>(articles);

  const articlesWrapper = useRef<HTMLDivElement | null>(null);

  const handleSelectChange = useCallback(
    (selectedOptionValue: string) => {
      if (selectedOptionValue === "all") {
        setFilteredArticles(articles);
        return;
      }

      const articleArray: Array<ContentstackArticle> = [];

      articles.forEach((article) => {
        if (
          !!article.categories.find(({ title }) => title === selectedOptionValue) ||
          !!article.tags.find(({ title }) => title === selectedOptionValue)
        ) {
          articleArray.push(article);
        }
      });

      setFilteredArticles(articleArray);
      setCurrentCardIndex(cardsToDisplayAtOnceNumber);
    },
    [articles],
  );

  const availableFilterOptions = useMemo<Array<DropdownOption>>(
    () => filterOptions.map(({ title }) => ({ id: title, title: title })),
    [filterOptions],
  );

  const handleLoadMoreClick = useCallback(() => {
    const newAmountOfCardsToDisplay = currentCardIndex + cardsToDisplayAtOnceNumber;

    newAmountOfCardsToDisplay <= filteredArticles.length
      ? setCurrentCardIndex(newAmountOfCardsToDisplay)
      : setCurrentCardIndex(filteredArticles.length);
  }, [currentCardIndex, filteredArticles]);

  const observerRef = useScrollObserver<HTMLDivElement | null>(animateArticleList, articlesWrapper, {
    triggerOnce: true,
  });

  return availableFilterOptions.length === 0 ? (
    <Loader />
  ) : (
    <>
      <section className={`container ${styles.articleList} ${isYoutubeSectionEnabled ? "" : styles.lastSection}`}>
        <div className={`content-wrapper ${styles.contentWrapper}`}>
          <div className={styles.dropdownWrapper}>
            <Dropdown
              className={styles.newsDropdown}
              onChange={handleSelectChange}
              options={[{ id: "all", title: defaultPreferenceText }, ...availableFilterOptions]}
              placeholder={`${placeholderText}...`}
            />
          </div>
        </div>

        <div ref={observerRef}>
          <div className={`content-wrapper ${styles.contentWrapper} ${styles.cardsWrapper}`} ref={articlesWrapper}>
            {filteredArticles.slice(0, currentCardIndex).map((article, index) => (
              <Fragment key={index}>
                <ArticleCard cardContent={article} displayCategory={true} />
                {isMobile && <span className={styles.dashDetail} />}

                {(index === displayPromoSlideIndexPosition ||
                  (index === filteredArticles.length - 1 && index <= displayPromoSlideIndexPosition)) &&
                  isPromoEnabled && <PromoAd key={promo.title} selectedPromo={promo} />}
              </Fragment>
            ))}
            {filteredArticles.length === 0 && isPromoEnabled && <PromoAd key={promo.title} selectedPromo={promo} />}
          </div>
        </div>
        {currentCardIndex < filteredArticles.length && (
          <div className={`content-wrapper ${styles.contentWrapper} ${styles.footerCta}`}>
            <PrimaryButton onClick={handleLoadMoreClick} label={loadMoreCtaText} hasWhiteBackground={true} />
          </div>
        )}
      </section>
    </>
  );
};

export const query = graphql`
  fragment ArticleList on Contentstack_news {
    loadMoreCtaText: newsfeed_cta
    preferences: preferences_module {
      placeholderText: preferences_cta
      defaultPreferenceText: all_option
      options: preferences_select {
        ... on Contentstack_categories {
          title
        }
        ... on Contentstack_tags {
          title
        }
      }
    }
  }
`;
