import { gsap, Power2 } from "gsap";
import React, { useEffect, useRef } from "react";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import styles from "./FeaturedNewsCard.module.scss";
import { CardLink } from "../../../general/CardLink/CardLink";
import { ContentstackArticle } from "../../../../data/types/Contentstack";

interface NewsCardProps {
  cardContent: ContentstackArticle;
  isActiveCard: boolean;
}

export const FeaturedNewsCard: React.FC<NewsCardProps> = ({
  cardContent: {
    title,
    categories: [{ title: category }],
    link: { url: linkUrl },
    description,
    featuredImage: {
      banner: { url: imageUrl },
    },
    articleType,
    youtubeLink,
    externalLink,
  },
  isActiveCard,
}) => {
  const isMobile = useIsMobile();

  const dashDetailRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (isActiveCard) {
      gsap.set(dashDetailRef.current, { css: { visibility: "visible" } });
      gsap.fromTo(dashDetailRef.current, { scaleX: 0 }, { duration: 0.5, scaleX: 1, ease: Power2.easeOut });
    }
  }, [isActiveCard]);

  return (
    <div className={styles.cardWrapper}>
      <CardLink articleType={articleType} linkUrl={youtubeLink || externalLink || linkUrl}>
        <span ref={dashDetailRef} className={styles.dashDetail} />
        <div className={styles.newsCard} style={{ backgroundImage: `url(${imageUrl})` }}>
          <div className={styles.cardContentWrapper}>
            <div className={styles.cardHeading}>
              <h5 className={`heading-03 font-normal ${styles.category}`}>{category}</h5>
            </div>
            <div className={styles.cardTitle}>
              <h4 className={`heading-02 font-normal ${styles.title}`}>{title}</h4>
            </div>
            {!isMobile && (
              <div className={`${styles.cardDescription} copy-02`}>
                <p>{description}</p>
              </div>
            )}
          </div>
        </div>
      </CardLink>
    </div>
  );
};
