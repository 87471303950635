import React, { FunctionComponent, useCallback, useRef } from "react";
import { ContentstackArticle } from "../../../../data/types/Contentstack";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { useMouseTracker } from "../../../../hooks/useMouseTracker";
import { useScrollObserver } from "../../../../hooks/useScrollObserver";
import { useSlider } from "../../../../hooks/useSlider";
import CustomCursor from "../../../general/CustomCursor/CustomCursor";
import Icon from "../../../general/Icon/Icon";
import { FeaturedNewsCard } from "../../cards/FeaturedNewsCard/FeaturedNewsCard";
import { NewsCard } from "../../cards/NewsCard/NewsCard";
import { animateNewsSlider } from "./animations";
import styles from "./NewsSlider.module.scss";

interface NewsSliderProps {
  slides: Array<ContentstackArticle>;
  isFeaturedNewsCardSlider?: boolean;
}

export const NewsSlider: FunctionComponent<NewsSliderProps> = ({ slides, isFeaturedNewsCardSlider = false }) => {
  const sliderContentRef = useRef<HTMLUListElement | null>(null);
  const {
    targetRef,
    data: { mousePosition, isMouseIn },
  } = useMouseTracker();
  const isMobile = useIsMobile();

  const {
    sliderRef,
    data: { activeSlideIndex, scrollSnaps },
  } = useSlider(isFeaturedNewsCardSlider && !isMobile ? { align: "start" } : {});

  const observerRef = useScrollObserver<HTMLUListElement | null>(animateNewsSlider, sliderContentRef);

  const setRefs = useCallback(
    (node) => {
      targetRef(node);
      observerRef(node);
    },
    [targetRef, observerRef],
  );

  return (
    <div className={isFeaturedNewsCardSlider ? styles.featuredNewsSlider : ""}>
      <div className={isFeaturedNewsCardSlider ? styles.customCursorWrapper : ""} ref={setRefs}>
        <div className={styles.slider} ref={sliderRef}>
          <ul
            className={`${styles.content} ${isFeaturedNewsCardSlider ? styles.featuredNewsSliderContent : ""}`}
            ref={sliderContentRef}
          >
            {!isFeaturedNewsCardSlider
              ? slides.map((slide, index) => (
                <li key={slide.title} className={styles.slide}>
                  <NewsCard cardContent={slide} isActiveCard={index === activeSlideIndex} />
                </li>
              ))
              : slides.map((slide, index) => (
                <li key={slide.title} className={styles.slide}>
                  <FeaturedNewsCard cardContent={slide} isActiveCard={index === activeSlideIndex} />
                </li>
              ))}
          </ul>
        </div>
        {isMouseIn && !isMobile && isFeaturedNewsCardSlider && (
          <CustomCursor wrapperIcon="CustomCursor" startPosition={mousePosition} />
        )}
      </div>
      {isFeaturedNewsCardSlider && (
        <div className={`${styles.bulletsContainer} ${isMobile ? `container ${styles.mobileBullets}` : ""}`}>
          <ul className={styles.bullets}>
            {scrollSnaps.map(({ }, index: number) => (
              <li key={index} className={`${styles.bulletWrapper} ${activeSlideIndex === index && styles.active}`}>
                <Icon className={styles.bulletIcon} name="Diamond" />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
