import { gsap, Power2 } from "gsap";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import Locale from "../../../../data/contexts/riot-locale";
import { ArticleType, ArticleTypeName } from "../../../../data/enum/ArticleType";
import { ContentstackArticle } from "../../../../data/types/Contentstack";
import { localizeDateString } from "../../../../utils/date";
import { CardLink } from "../../../general/CardLink/CardLink";
import Icon from "../../../general/Icon/Icon";
import { icons } from "../../../general/Icon/icons";
import styles from "./NewsCard.module.scss";

interface NewsCardProps {
  cardContent: ContentstackArticle;
  isActiveCard?: boolean;
  animationDelay?: number;
}

const articleTypeToIconName = (type: ArticleTypeName): keyof typeof icons => {
  const iconNames: Record<ArticleTypeName, string> = {
    Youtube: "ArticleYoutube",
    "External link": "ArticleExternalLink",
    "Normal article": "ArticleNormalArticle",
    "Youtube video": "ArticleYoutubeVideo",
  };
  return iconNames[type] as keyof typeof icons;
};

export const NewsCard: React.FC<NewsCardProps> = ({
  cardContent: {
    title,
    categories: [{ title: category }],
    date,
    link: { url: linkUrl },
    featuredImage: {
      banner: { url: imageUrl },
    },
    articleType,
    youtubeLink,
    externalLink,
  },
  isActiveCard,
  animationDelay = 0,
}) => {
  const dashDetailRef = useRef<HTMLSpanElement>(null);

  const locale = useContext(Locale);
  const localizedDate = useMemo(() => localizeDateString(date, locale), [date, locale]);

  useEffect(() => {
    if (isActiveCard || animationDelay > 0) {
      gsap.fromTo(
        dashDetailRef.current,
        { scaleX: 0 },
        { duration: 0.5, scaleX: 1, ease: Power2.easeOut, delay: animationDelay },
      );
    }
  }, [isActiveCard, animationDelay]);

  return (
    <div className={styles.cardWrapper}>
      <CardLink articleType="External link" linkUrl={youtubeLink || externalLink || "/vi-vn" + linkUrl}>
        <span ref={dashDetailRef} className={styles.dashDetail} />
        <div className={styles.newsCard} style={{ backgroundImage: `url(${imageUrl})` }}>
          <div className={styles.cardContentWrapper}>
            <div className={styles.cardHeading}>
              <span className={`heading-03 font-normal ${styles.date}`}>{localizedDate}</span>
              <Icon className={styles.ellipseIcon} name="Ellipse" />
              <h5 className={`heading-03 font-normal ${styles.category}`}>{category}</h5>
            </div>
            <div className={styles.cardTitle}>
              <Icon className={styles.titleIcon} name={articleTypeToIconName(articleType)} />
              <h4 className={`heading-05 font-normal ${styles.title}`}>{title}</h4>
            </div>
          </div>
        </div>
      </CardLink>
    </div>
  );
};
