import { gsap, Power1 } from "gsap";

export const animateNewsSlider = (sliderContentRef: HTMLUListElement | null) => {
  if (!sliderContentRef) return;

  const tl = gsap.timeline({ paused: true });
  tl.from(sliderContentRef.children, {
    y: 30,
    duration: 1,
    autoAlpha: 0,
    stagger: 0.3,
    ease: Power1.easeOut,
  });

  return tl;
};
