import { CSSPlugin, gsap, Power1 } from "gsap";
gsap.registerPlugin(CSSPlugin);

const duration = 0.5;
export const animateFeaturedNews = (sectionElement: HTMLElement | null) => {
  if (!sectionElement) return;

  const tl = gsap.timeline({
    defaults: { duration, ease: Power1.easeInOut },
  });

  tl.fromTo(sectionElement, { opacity: 0 }, { opacity: 1 });

  return tl;
};
